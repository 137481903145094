
body.about_us_page #main-bottom-a-row{
        padding-top: 7rem;
        padding-bottom: 7rem;
}

body.about_us_page .whatwedo_mod .customModuleTitle,
body.about_us_page .whatwedo_mod .customModuleTitle .titleFirst{
        color: #fff;
        font-size: 2.5rem;
}

body.about_us_page .whatwedo_mod .customModuleTitle .titleFirst{
        font-weight: 900;
}

.whatwedo_mod .modulePretext{
        color: #fff;
        font-size: 1.25rem;
        line-height: 2rem;
        padding-right: 1rem;
        margin-bottom: 2rem;
}

.gain_block{
        position: relative;
}
.gain_line{
        width: 100%;
        height: 3px;
        background: #757575;
}

.icon-block i{
        color: #fff;
        font-size: 2.25rem;
}

.icon-block{
        padding-right: 1rem;
}

.gain{
        height: 3px;
        background: #fff;
        position: absolute;
}

.mod-item-title{
        font-size: 1.063rem;
        color: #fff;
        text-align: right;
        font-weight: 300;
}

.giant_block{
        width: 100%;
}

.flex{
        display: flex;
}

.giant_item{
        margin-bottom: 3rem;
}
