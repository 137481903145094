.nav ul li {
        list-style: none;
        font-size: 0.7rem;
}

.navbar-nav>li>span,
.navbar-nav>li>a{
        padding: 0;
        display: block;
        position: relative;
        line-height: 1.5rem;
        padding: 0.3rem 0.5rem;
        border: 2px solid transparent;
}


/*.home_page */section.header .nav>li>a,
/*.home_page */section.header .nav>li>span {
        color: #fff;
}

/*.home_page */section.header.fix-to-top.nonzero-position .nav>li>a,
/*.home_page */section.header.fix-to-top.nonzero-position .nav>li>span,
body.spacer-top section.header.fix-to-top .nav>li>a,
body.spacer-top section.header.fix-to-top .nav>li>span {
        color: #666;
        font-size: 0.8rem;
        padding: 0.5em 0.7em;
}

.nav>li.active>a,
.nav>li.active>span {
        /*color: */
        font-weight: 700;
}

/*.nav>li>a:focus,*/
/*.nav>li>a:hover {*/
/*        background: none;*/
/*}*/

.nav>li.deeper>a:after,
.nav>li.deeper>span:after {
        font-family: 'FontAwesome';
        content: '\f107';
        float: right;
        line-height: 20px;
        margin-left: 5px;
}

body.msie .nav>li.deeper>a:after,
body.msie .nav>li.deeper>span:after {
        float: none;
}

.nav li.deeper > ul.nav-child {
        display: none;
        /*opacity: 0;*/
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        width: 250px;
        background: #f5f5f5;
        padding: 0;
        z-index: 99999;
}

.nav li.deeper > ul.nav-child li a,
.nav li.deeper > ul.nav-child li span {
        padding: 13px 20px;
        display: block;
        color: #545454 !important;
        border-bottom: 1px solid #eaeaea;
}

.nav li.deeper ul.nav-child li.deeper > a:after,
.nav li.deeper ul.nav-child li.deeper > span:after {
        font-family: 'FontAwesome';
        content: '\f105';
        /*float: right;*/
        margin-left: 15px;
}

.nav li.deeper > ul.nav-child > li:hover a,
.nav li.deeper > ul.nav-child > li.active > a{
        background: #fff;
        color: #333 !important;
}

.nav li.active > a {
        /*color: #7EA73F !important;*/
}

.nav li.deeper > ul.nav-child a,
.nav li.deeper > ul.nav-child span {
        color: #333;
}

.nav.horizontal>li>a.nav-link {
        padding: 0.8em 1em;
}

/*.nav.horizontal>li:first-child>a {*/
/*        padding-left: 0;*/
/*}*/
/**/
/*.nav.horizontal>li:last-child>a {*/
/*        padding-right: 0;*/
/*}*/

.nav.horizontal li.deeper {
        position: relative;
}

.nav.horizontal li.deeper:hover > ul.nav-child {
        display: block;
        /*opacity: 1;*/
        -webkit-animation: fadeInDown 0.3s;
        animation: fadeInDown 0.3s;
}

.nav.horizontal li.deeper ul.nav-child {
        position: absolute;
        top: 38px;
        right: 0;
}

/*.home_page */section.header.fix-to-top.nonzero-position .nav.horizontal li.deeper ul.nav-child {
        top: 37px;
}

.nav.horizontal>li.deeper>ul.nav-child ul.nav-child {
        top: -15px;
        left: 250px;
        border-left: 2px solid #eee;
}

.sidebar .nav{
        display: block;
}

.sidebar-mod {
        margin-top: 30px;
}
.sidebar .container-fluid{
        margin: 0;
        padding: 0;
}

.sidebar ul.side_menu_list li{
        border-bottom: 1px solid #ddd;
        padding: 1rem;
}

.side_menu_top_line{
        height: 40px;
}
.sb-close-button{
        right: 0;
}
.sb-close-button i{
        padding: 7px 15px;
        border: 1px solid #ddd;
        font-size: 3.5rem;
        color: #444444;
}

.sidebar .nav>li>a.home-link:after{
        content: none !important;
}

.sidebar .nav .active a{
        font-weight: 600 !important;
}

.sidebar .row-container{
        padding: 0;
}
