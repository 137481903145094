.nav.navbar-nav > li:first-of-type > a,
.nav.navbar-nav > li:first-of-type > span{
        padding-left: 0;
}

.nav.navbar-nav > li:first-of-type a.btn {
        padding-left: 0.5rem;
}

.nav.navbar-nav.pull-right > li:last-of-type > a,
.nav.navbar-nav.pull-right > li:last-of-type > span{
        padding-right: 0;
}

.nav.navbar-nav.pull-right > li:last-of-type a.btn {
        padding-right: 0.5rem;
}

.pull-center .nav {
        float: none;
        display: block;
        text-align: center;
}

.pull-center .nav > li {
        float: none;
        display: inline-block;
}

.showcase-features .nav-item .fa-facebook-f {
        font-size: 30px;
        line-height: 30px;
}