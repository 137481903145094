.service-box-mod .mod-item-heading{
    font-size: 1rem;
    color: #222222;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 2rem;
}

.service-box-mod .sub_element{
    margin-bottom: 1.5rem;
}
