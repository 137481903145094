.pull-center .categories-list > .row {
        display: flex;   
        display: -webkit-flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        justify-content: center;
        -webkit-justify-content: center;
}

.mod-category-button {
        margin-bottom: 30px;
}

ul.subcat_menu{
        list-style: none;
        padding: 0;
        margin: 0;
}

.subcat_menu li{
        display: inline-block;
        padding: 0 0.5rem;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 400;
        border-right: 1px solid #ddd;
        line-height: 2rem;
}

.subcat_menu li:first-child{
        padding-left: 0;
}

.subcat_menu li:last-child{
        border: none;
}

.subcat_menu li.active a{
        font-weight: 600;
}