.nav-vertical.flex-column > li {
        padding-bottom: 10px;
}

.sidebar .nav>li>a,
.sidebar .nav>li>span {
        text-transform: none !important;
        display: block;
}

.sidebar .nav>li>a:after {
        font-family: "FontAwesome";
        float: right;
        width: 20px;
        text-align: center;
        font-size: 16px;
}

ul.nav-vertical a,
ul.nav-vertical a:active{
    font-weight: 300 !important;
}

ul.nav-vertical a:hover{
    color: #666666;
}

.sidebar .nav>li>a.home-link:after {
        content: '\f015';
}

.sidebar .nav>li>a.aboutus-link:after {
        content: '\f129';
}

.sidebar .nav>li>a.mobility-link:after {
        content: '\f02d';
}

.sidebar .nav>li>a.practice-link:after {
        content: '\f085';
}

.sidebar .nav>li>a.resources-link:after {
        content: '\f008';
}

.sidebar .nav>li>a.events-link:after {
        content: '\f274';
}

.sidebar .nav>li>a.contactus-link:after {
        content: '\f2ba';
}

.footer_menu_link{
    display: none;
}

#footer-a-row .footer_menu_link{
    display: block;
}
