




section.breadcrumbs{
        background: url('../images/brcrmb_bkg.jpg') left top no-repeat;
        margin-top: 80px;
        background-size: cover;
}

ol.breadcrumb {
        padding: 0;
        background: none;
        margin-bottom: 1.4rem;
}

ol.breadcrumb li,
ol.breadcrumb li b {
        font-weight: 300;
        font-size: 1rem;
        color: #fff;
}

.breadcrumb-item.active, .breadcrumb-item a{
        color: #fff;
}

.breadcrumb-item + .breadcrumb-item::before{
        color: #fff;
}

.breadcrumb_title{
        color: #fff;
        font-size: 3rem;
        font-weight: 300;
        letter-spacing: 10px;
        text-transform: uppercase;
}

.breadcrumb_title span:first-child{
        font-weight: 900;
}
