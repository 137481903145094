.slick-carousel button.slick-arrow {
        background: none;
        border: none;
        font-size: 0;
        position: absolute;
        top: 50%;
        margin-top: -18px;
        height: auto;
}

.slick-carousel button.slick-arrow:focus {
        outline: none;
}

.slick-carousel button.slick-arrow:before {
        font-family: 'FontAwesome';
        font-size: 30px;
        color: #333;
}

.slick-carousel button.slick-prev.slick-arrow {
        left: -20px;
}

.slick-carousel button.slick-prev.slick-arrow:before {
        content: '\f104';
}

.slick-carousel button.slick-next.slick-arrow {
        right: -20px;
}

.slick-carousel button.slick-next.slick-arrow:before {
        content: '\f105';
}