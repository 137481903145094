.footer-news-block{
    font-weight: 300;
}

.footer-news-block div.mod-item-heading{
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px #333333 solid;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
}

.footer-news-block .mod-item-title{
 font-size: 1rem;
}


.footer-news-block a{
    color: #fff;

}

.footer-news-block a:hover{
    color: rgb(204, 204, 204);
}

