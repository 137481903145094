#content-wrapper,
#footer-wrapper {
        z-index: 20;
}

.slide-fullscreen.paralax .carousel-container {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 10;
}

.slide-fullscreen.paralax .carousel-inner {
        width: 100%;
        height: 100%;
}

.slide-fullscreen.paralax .carousel-caption {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        padding-top: 0;
        padding-bottom: 0;
        color: #fff;
        text-align: center;
        height: 100%;
}

.slide-fullscreen.paralax .carousel-caption .container, .slide-fullscreen.paralax .carousel-caption .row, .slide-fullscreen.paralax .carousel-caption .slider_col_inner{
        height: 100%;
}

.slider_col_inner{
        /* background: rgba(0, 0, 0, 0.5); */
        padding: 80px;
        display: flex;
}

.scroll-page.d-flex.flex-column{
        display: none !important;
}

.carousel-caption .mod-intro-text{
        margin: auto 0 auto auto;
}

.slide_content_line{
        text-align: right;
        text-transform: uppercase;

}

.line_1{
        font-size: 96px;
        font-weight: 300;
        line-height: 130px;
}

.line_2{
        font-size: 96px;
        font-weight: 900;
        line-height: 130px;
        margin-bottom: 20px;
}

.line_3{
        font-size: 48px;
        font-weight: 300;
        line-height: 80px;
}

.line_4{
        font-size: 48px;
        font-weight: 900;
        line-height: 80px;
}


.carousel-item{
        height: 100%;
}

.slide-fullscreen.paralax .carousel-indicators {
        bottom: 60px;
}

.slide-fullscreen.paralax .item {
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        width: 100%;
        height: 100%;
}

.slide-fullscreen.paralax .yt-video {
        position: relative;
}

.slide-fullscreen.paralax .yt-video iframe {
        /*width: 100% !important;*/
        /*height: 100% !important;*/
}

.slide-fullscreen.paralax .yt-video:after,
.slide-fullscreen.paralax .mp4-video:after{
        background: #fff;
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
}

.slide-fullscreen.paralax .yt-video.loaded:after,
.slide-fullscreen.paralax .mp4-video:after {
        opacity: 0;
}

.slide-fullscreen.paralax .mp4-video {
        width: 100%;
        height: 100%;
}

.slide-fullscreen.paralax .mp4-video video {
        position: fixed;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -100;
        transform: translateX(-50%) translateY(-50%);
        transition: 1s opacity;
}

.carousel-fade .carousel-inner .item {
        opacity: 0;
        transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
        opacity: 1;
}

.carousel-fade .carousel-control {
        z-index: 2;
}

/** fade animation **/
@media all and (transform-3d), (-webkit-transform-3d) {
        .carousel-fade .carousel-inner > .item.next,
        .carousel-fade .carousel-inner > .item.active.right {
                opacity: 0;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        }
        .carousel-fade .carousel-inner > .item.prev,
        .carousel-fade .carousel-inner > .item.active.left {
                opacity: 0;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        }
        .carousel-fade .carousel-inner > .item.next.left,
        .carousel-fade .carousel-inner > .item.prev.right,
        .carousel-fade .carousel-inner > .item.active {
                opacity: 1;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        }
}

.carousel-item {
        position: relative;
}

#survey{
        position: absolute;
        display: block;
       bottom: 45%;
       right: 5%;
       z-index: 20;
}

.survey-block{
        position: relative;
        background: #fff;
        box-shadow: 0 1px 19px 4px rgba(111, 111, 111, 0.12);
        padding: 30px;
        max-width: 360px;
}

#survey h3{
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 40px;
        text-transform: uppercase;
        font-weight: 400;

}

#survey h3 strong{
        font-weight: 700;
}

#survey .btn{
        font-size: 14px;
        padding: 8px 20px;
        font-weight: 400;
}

#icon_q{
        font-size: 80px;
        color: #656565;
        position: absolute;
        top: -57px;
        right: 20px;
        font-weight: 400;
        z-index: 31;
        cursor: pointer;
        border-radius: 100px;

}

.puls.active{
        position: absolute;
        top: 60px;
        left: 14px;
        width: 5px;
        height: 5px;
        border-radius: 50px;
        animation: pulse 2s infinite;
}


@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(138, 138, 138, 0.35);
  }
  70% {
      -webkit-box-shadow: 0 0 35px 55px rgba(138, 138, 138, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(138, 138, 138, 0);
  }
}
@keyframes pulse {
  0% {
  -moz-box-shadow:  0 0 0 0 rgba(138, 138, 138, 0.35);
    box-shadow: 0 0 0 0 rgba(138, 138, 138, 0.35);
  }
  70% {
    -moz-box-shadow: 0 0 35px 55px rgba(138, 138, 138, 0);
       box-shadow: 0 0 35px 55px rgba(138, 138, 138, 0);
  }
  100% {
     -moz-box-shadow: 0 0 0 0 rgba(138, 138, 138, 0);
      box-shadow: 0 0 0 0 rgba(138, 138, 138, 0);
  }
}

#icon_q:hover{
        color: #000;
}

.survey-inner{
        position: relative;
        opacity: 0;
        transition: all .2s;
}

.survey-inner.visible{
        opacity: 1;
        transition: all .2s;
}

.survey_arrow{
        position: absolute;
        left: -320px;
        top: 140px;
}

@media only screen and (max-width : 1500px) {
    .survey_arrow{
        position: absolute;
        left: -220px;
        top: 140px;
        width: 200px;
        height: 280px;
    }

    #survey{
        bottom: 50%;
    }
}

@media only screen and (max-width: 1300px){
    .survey_arrow {
        position: absolute;
        left: -190px;
        top: 140px;
        width: 180px;
        height: 200px;
    }

    .survey-block{
        max-width: 310px;
    }
}


@media only screen and (max-width : 992px) {

    .survey_arrow {
        position: absolute;
        left: -190px;
        top: 140px;
        width: 150px;
        height: 180px;
    }

    #survey {
        right: 2%;
    }

    .survey-block{
        padding: 20px;
        max-width: 300px;
    }

}


@media only screen and (max-width : 768px) {
    #survey {
        display: none !important;
        z-index: 0;
    }
}
