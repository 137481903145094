.about-services .btn-dark{
    background-color: #222222;
    border-color: #fff;
    color: #fff;
    border-radius: 24px;
    padding: 0.5rem 1.5rem;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
	text-transform: uppercase;
	font-size: 0.75rem;
}

.about-services .btn-dark:hover{
    background: #fff;
    color: #222222;
    border-color: #222222;
}

.about-services .ab-words{
    word-spacing: -1.2rem;
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
    letter-spacing: 0.5rem;
    font-weight: 300;
    line-height: 3rem;
}

.about-services .ab-title{
    font-weight: 900;
    display: inline-block
}

.about-services .custom{
	font-weight: 300;
	letter-spacing: 1.2px;
	line-height: 26px;
}

.moduletable.blog_module .custom{
    font-weight: 300;
    color: #444444;
}

.footer_contacts a{
    color: #fff;
}

.footer_contacts .contact_line{
    margin-bottom: 15px;
}

.footer_contacts .contact_phone a:before{
    font-family: 'Pe-icon-7-stroke';
    content: "\e670";
    margin-right: 10px;
    font-size: 20px;
}

.footer_contacts .contact_email a:before{
    font-family: 'Pe-icon-7-stroke';
    content: "\e63b";
    margin-right: 10px;
    font-size: 20px;
}

.footer_contacts .contact_address a:before{
    font-family: 'Pe-icon-7-stroke';
    content: "\e6bb";
    margin-right: 10px;
    font-size: 20px;
}

/* about us  */

body.about_us_page #header-a-row{
    /* background: url('../../../../../images/bkg1.jpg') left top no-repeat; */
    padding-top: 12rem;
    padding-bottom: 10rem;
}

body.about_us_page .module_about_1 .customModuleTitle, body.about_us_page .module_about_1 .customModuleTitle .titleFirst{
    font-weight: 300;
    font-size: 3.688rem;
    letter-spacing: 12px;
    color: #202020;
}

body.about_us_page .customModuleTitle div{
    display: inline-block;
    margin-right: 5px;
}

body.about_us_page .customModuleTitle strong{
    font-weight: 900;
}

body.about_us_page .custom{
    font-size: 1.188rem;
}



.two_cols{
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
}

body.about_us_page #header-b-row{
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.module_about_2 h2{
    font-weight: 300;
    font-size: 2.375rem;
    text-transform: uppercase;
    color: #202020;
    margin-bottom: 2rem;
    letter-spacing: 12px;
}

.module_about_2 h2 strong, body.about_us_page  .module_about_4 .customModuleTitle .titleFirst{
    font-weight: 900;
}

body.about_us_page .module_about_3 .customModuleTitle{
    font-size: 1.875rem;
}

body.about_us_page .module_about_3 .customModuleTitle .titleFirst{
    font-weight: 900;
    font-size: 1.875rem;
}

body.about_us_page  .module_about_4 .customModuleTitle, body.about_us_page  .module_about_4 .customModuleTitle .titleFirst{
    color: #fff;
    font-size: 2.375rem;
}

.moduletable.footer_contacts .module-content a{
    font-size: 1rem;
}

.h1_home_mod .moduleTitle{
    font-size: 32px;
}

@media only screen and (max-width : 480px) {
    .h1_home_mod .moduleTitle{
        font-size: 28px;
        letter-spacing: 4px;
    }
}


@media only screen and (max-width : 380px) {
    .h1_home_mod .moduleTitle{
        font-size: 20px;
        letter-spacing: 3px;
    }

   .h1_home_mod .customModuleTitle{
        line-height: 2rem;
    }

    .h1_home_mod.customLogo .customModuleTitle::before{
        content: none;
        display: none;
        height: 0;
        background: transparent;
    }

    .h1_home_mod.customLogo .title_line:before{
        content: none;
        display: none;
        height: 0;
        background: transparent;
    }
}
