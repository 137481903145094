.client-layout{
    padding: 0;
}

.client-layout .mod-item-image{
    margin-bottom: 0;
}

.client-layout .mod-intro-text{
    margin-bottom: 0;
}

.clients-block .client-name{
    position: absolute;
    left: 50%;
    top: 80%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    display: block;
}

.clients-block .client-search-block{
    display: block;
    overflow: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 12;
}

.clients-block .client-search-icon{
    position: absolute;
    display: block;
    left: 50%;
    top: -5%;
    transform: translate(-50%, -50%);
    transition: .2s ease-in-out;
    -moz-transition: .2s ease-in-out;
    -webkit-transition: .2s ease-in-out;
    z-index: 12;
}

.client-search-icon .fa-search{
    font-size: 26px;
    color: #fff;
}

.clients-block .client-name .mod-item-title{
    color: #fff;
}

.clients-block .client-name .mod-item-title{
    position: relative;
    top: 4rem;
    font-size: 1rem;
    /* transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -webkit-transition:  4s ease-in-out; */
}

.clients-block .client-name .mod-item-title .mod-intro-text{
    font-size: 1rem;
    color: #888888;
    text-transform: none;
}

.clients-block .client-overlay{
    background: rgba(43, 43, 43, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: block;
    color: #FFF;
    outline: 1px solid #fff;
    outline-offset: -1.5625rem;
    transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -webkit-transition: opacity .2s ease-in-out;
    z-index: 10;
}

.clients-block .client-layout:hover .client-overlay{
    opacity: 1;
    transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -webkit-transition: opacity .2s ease-in-out;

}

.clients-block .client-layout:hover .mod-item-title{
    top: 0;
}

.clients-block .client-layout:hover .client-search-icon{
    top: 50%;
}

.clients-block .client-layout:hover + .client-overlay,
.clients-block .client-layout:hover + .client-name{
    display: block;
}

.clients-block .client-logo img{
    width: 100%;
}

.all-clients .custom-urls a:after{
    top: -8px;
}

.clients-block .custom-urls {
    margin: auto;
}

.all-clients .custom-urls a:after{
    font-family: 'Pe-icon-7-stroke';
    content: "\e684";
    position: absolute;
    font-size: 3rem;
     margin-left: 0;
    transition: .2s ease-in-out;
    -moz-transition: .2s ease-in-out;
    -webkit-transition:  .2s ease-in-out;
}

.all-clients:hover .custom-urls a:after{
    margin-left: 10px;
    transition: .2s ease-in-out;
    -moz-transition: .2s ease-in-out;
    -webkit-transition:  .2s ease-in-out;
}

.all-clients a{
    font-size: 2rem;
    text-transform: uppercase;
        letter-spacing: 4px;
}

