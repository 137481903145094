.showcase .module-content .page-header .filter {
        padding-top: 70px;
        background: rgba(15,14,14,0.5);
}

.showcase .module-content .page-header .page-header-title {
        min-height: 250px;
}

.showcase .module-content .page-header .page-header-title h1,
.showcase .module-content .page-header .page-header-title h2 {
        font-weight: 500;
}

body.msie .showcase .module-content .page-header .page-header-title h1,
body.msie .showcase .module-content .page-header .page-header-title h2 {
        margin-top: 93px !important;
}

.showcase .module-content .page-header .page-header-title h1 b,
.showcase .module-content .page-header .page-header-title h2 b{
        font-weight: 700;
}

.showcase .module-content .page-header .page-header-title h1.line:after,
.showcase .module-content .page-header .page-header-title h2.line:after{
        background-color: #fff;
        height: 2px;
}