.sidebar-togler .togler {
        padding: 0.3rem 0;
        display: block;
}

.sidebar-togler .togler.lg-text {
        font-size: 2.2rem;
        padding: 0.1rem 0;
        line-height: 0.9rem;
}

.sidebar-togler .togler:hover {
        cursor: pointer;
}

section.header.fix-to-top.nonzero-position .togler,
body.spacer-top section.header.fix-to-top .togler {
        color: #7EA73F !important;
}