.work-wu-block .work-item-icon{
    font-size: 3rem;
    padding: 0;
    color: #212121;
}

.work-wu-block .custom-urls{
    margin: 1rem 0 0 3rem;
}

.work-wu-block .work-item-icon.item-0 i::after{
    content: "";
    position: relative;
    border-bottom: 1px solid #ddd;
    top: 0px;
    left: 0px;
    width: 8rem;
    display: block;
    transform: translate(-9rem, -1.5625rem);
    /*transform: translate(-8rem, -1.5625rem);*/
}

.work-wu-block .work-item-icon.item-1 i::after{
    content: "";
    position: relative;
    border-bottom: 1px solid #ddd;
    top: 0px;
    left: 0px;
    width: 7rem;
    display: block;
    transform: translate(-8rem, -1.5625rem);
}
