.footer-logos .items-block > .row > div:last-child .mod-item-image {
        margin-bottom: 0;
}

.about-activities h2.moduleTitle {
        margin-bottom: 50px;
}

.about-activities p.modulePretext {
        text-align: center;
        margin-bottom: 50px;
}

.about-activities .items-block.cards-list .card .card-text {
        padding: 0;
        border: none;
        margin: 0;
}

.about-activities .card-body ul {
        padding: 0;
        list-style: none;
}

.about-activities .card-body ul li {
        margin-bottom: 10px;
}

.about-activities .card-body ul li:before {
        font-family: 'FontAwesome';
        content: '\f101';
        margin-right: 5px;
        color: #7EA73F;
}

.moduletable.logo_line_mod{
        padding-top: 2rem;
        padding-bottom: 2rem;
}

.moduletable.logo_line_mod .mod-item-image{
        margin: 0;
}
