#content-wrapper,
#footer-wrapper {
        z-index: 20;
}

.item-current.paralax {
        position: relative;
}

.item-current.paralax .current-item-container {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 10;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
}

.item-current.paralax .current-item-caption {
        position: absolute;
        top: 40%;
        left: 15%;
        right: 15%;
        background: none;
        padding: 30px;
        background: rgba(255,255,255,0.3);
}