.search .form-group {
        display: flex;
        justify-content: center;
}

.search input[type="search"] {
        border-right: none;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-width: 1px;
}

.search button {
        line-height: 1rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 0.5rem 1rem;
        border-width: 1px;
}

.search button:hover {
        background: #fff;
        border-color: #ccc;
}