
.moduletable.side_form {
    position: sticky;
    top: 90px;
    z-index: 20;
}

.mod_its_form .form-control {
        border-width: 1px;
}

.mod_its_form textarea {
        height: 100px !important;
}

.mod_its_form mark {
        display: none !important;
}

.mod_its_form .form-control.error {
        border: 1px solid #d25548;
}

.side_form .moduleTitle {
        font-size: 1.875rem;
        color: #212121;
        letter-spacing: 5px;
}

.side_form .pretext{
        text-align: center;
        margin-bottom: 1.5rem;
}

.side_form .pretext strong{
        font-weight: 600;
        font-size:  1.125rem;
}

.side_form .mod_its_form .form-control{
        border-radius: 2px;
        background: none;

}

.side_form .btn.btn-success{
        background: #222222;
        color: #fff;
        border: 1px solid #222;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 700;
        letter-spacing: 3px;
        margin-top: 1rem;
        padding: 1rem 1.5rem;
}

.side_form .btn.btn-success:hover{
        color: #222;
        background: #fff;
       border: 1px solid #222;
}

.control-group-captcha{
    margin-bottom: 10px;
}

.mod_its_form .form-group .control p {
        text-align: left;
}