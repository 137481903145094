.logo a {
        line-height: 1.5rem;
        display: block;
}

.logo .logo_title {
        font-size: 0;
        display: block;
        width: 0;
        height: 0;
}

.logo-module > .pull-right {
        width: 100%;
}

.logo h1,
.logo h2,
.logo h3,
.logo h4,
.logo h5 {
        margin: 0;
}

/*.home_page */section.header .logo_orig,
section.header .logo_light,
/*.home_page */section.header.fix-to-top.nonzero-position .logo_light,
body.spacer-top section.header.fix-to-top .logo_light{
        display: none;
}

/*.home_page */section.header .logo_light {
        display: block;
        width: 100%;
        max-width: 150px;
}

/*.home_page */section.header.fix-to-top.nonzero-position .logo_orig,
body.spacer-top section.header.fix-to-top .logo_orig{
        display: block;
        width: 100%;
        max-width: 150px;
}

.logo-link img {
        float: right;
        width: 98px;
        padding: 0.4rem;
}
