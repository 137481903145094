.services-block {
    margin-top: 5.5rem;
}

.services-block .service{
    color: #fff;

}

.services-block .service-item:after{
    content: '';
    height: 100px;
    width: 1px;
    background-color: #444444;
    position: absolute;
    right: 0;
    top: 35px;

    /* border-right: 1px solid #444444;     */
}

.services-block .item-1:before{

    content: '';
    height: 100px;
    width: 1px;
    background-color: #444444;
    position: absolute;
    left: 0;
    top: 35px;

}

.no-margin-right{
    margin-right: 0;
}

.service_block_inner{
    /* padding: 0 15px; */
}


.services-block .service-body{
    overflow: hidden;
}

.services-block .service-body a, .services-block .service-body a:hover{
    color: #fff;
}

.services-block .service-icon{
    position: relative;
    font-size: 5rem;
    margin-bottom: 2rem;
    top: 0;
    transition: top ease .5s;
}

.services-block .service-title{
    position: relative;
    top: 0;
    transition: top ease .5s;
    letter-spacing: 4px;
    font-weight: 400;
}

/* .services-block .service-body:hover {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .5s, opacity .5s linear;
} */

.services-block .service-body:hover .service-icon {
    top: -8rem;
}

.services-block .service-body:hover .service-title{
    top: 8rem;
}

.services-block .service-introtext{
    position: absolute;
    top: 0;
    padding-right: 1.9375rem;
    opacity: 0;
    visibility: hidden;
    transition: visibility .4s ease, opacity .4s ease;

}

.services-block .service-body:hover .service-introtext{
    visibility: visible;
    opacity: 1;
}


