

.testimonials_mod .modulePretext{
        font-size: 1.25rem;
        line-height: 2rem;
        letter-spacing: 1px;
}

.testimonials_mod .mod-item-title{
        color: #444444;
        font-size: 1.375rem;
}

.testimonials_mod .slide_fulltext{
        background: #222222;
        color: #fff;
        padding: 2.5rem 2.5rem 2rem 2.5rem;
        font-size: 1.5rem;
        line-height: 2.2rem;
        letter-spacing: 2px;
        margin-bottom: 1.5rem;
}

.testimonials_mod .carousel-indicators li{
        background-color: #cccccc;
}

.testimonials_mod  .carousel-indicators .active{
        background-color: #444444;
}

.testimonials_mod  .mod-intro-text{
        color: #444444;
        font-size: 1.25rem;
}

.testimonials_mod  .carousel-indicators{
        top: -2rem;
        right: 0;
        margin: 0;
        left: unset;
        bottom: unset;
        width: auto;
}

.testimonials_mod .carousel-indicators li{
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
}

.img-circle{
        border-radius: 50%;
        border: 1px solid #D7D7D7;
}

.testimonial_info_block, .testimonial_image_block{
        margin-top: auto;
        margin-bottom: auto;
}

.testimonial_image_block{
        margin-right: 1.5rem;
}

body.about_us_page  .testimonials_mod .customModuleTitle, body.about_us_page  .testimonials_mod .customModuleTitle .titleFirst {
        font-size: 2.375rem;
}

body.about_us_page  .testimonials_mod .customModuleTitle .titleFirst{
        font-weight: 900;
}

.testimonials_mod .customLogo .customModuleTitle::before{
        padding-top: 5px;
}


.carousel-fade .carousel-inner .carousel-item {
        opacity: 0;
        transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
        opacity: 1;
}

.carousel-fade .carousel-control {
        z-index: 2;
}

/** fade animation **/
@media all and (transform-3d), (-webkit-transform-3d) {
        .carousel-fade .carousel-inner > .carousel-item.next,
        .carousel-fade .carousel-inner > .carousel-item.active.right {
                opacity: 0;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        }
        .carousel-fade .carousel-inner > .carousel-item.prev,
        .carousel-fade .carousel-inner > .carousel-item.active.left {
                opacity: 0;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        }
        .carousel-fade .carousel-inner > .carousel-item.next.left,
        .carousel-fade .carousel-inner > .carousel-item.prev.right,
        .carousel-fade .carousel-inner > .carousel-item.active {
                opacity: 1;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        }
}

.moduletable.testimonials_mod .module-inner{
        margin-top: auto;
        margin-bottom: auto;
}
