.pull-center .items-block > .row {
        display: flex;   
        display: -webkit-flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        justify-content: center;
        -webkit-justify-content: center;
}

.mod-item-image {
        margin-bottom: 20px;
}

.mod-intro-text {
        margin-bottom: 20px;
}