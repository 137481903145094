.blog-card-title .card-title{
    margin: 0;
}

.blog-card-title{
    background-color: #222;
    border-bottom: 0.1rem #333 solid;
    padding: 22px 0 22px 0;
}

.blog-card-other{
    padding: 15px 0 15px 0;
    background-color: #222;
}

.blog-card-category{
    display: inline-block;
    padding-left: 5px;
}

